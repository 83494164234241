.container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.25rem;
  }
  
  .item {
    display: flex;
    align-items: center;
    border: 1px solid gray;
    border-radius: 5px;
    padding: 2px;
  }
  
  .label {
    display: block;
    margin-right: 1rem;
    font-weight: 500;
  }
  
  .content {
    padding: 0.75rem;
    border-radius: 0.375rem;
  }
  
  .itemRole{
    display: flex;
    align-items: center;
    border: 1px solid gray;
    padding: 25px;
    // border-radius: 5px;
    padding: 2px;
  }