.body {
  height: calc(100% - 198px);
  min-height: calc(100vh - 60px);
  padding-top: 70px;
  padding-bottom: 150px;
}

.menu {
  margin-right: 25px;
}

.logo {
  max-width: 141px;
  margin-left: 25px;
  height: 70%;
}

.summary {
  margin-top: 20px;
  padding: 10px 10px 20px 10px;

  .alert-box>div {
    min-width: 350px;
  }

  p {
    margin-bottom: 12px;
  }
}

.button.alert {
  font-weight: 400;
  padding: 5px 20px;
  border-radius: 10px;
  width: auto;
  height: fit-content;
  background-color: #30a953;
  border-color: #30a953;

  &:hover {
    color: #30a953;
    border-color: #30a953;
  }
}

.alert-table {
  .search-box {
    margin: 14px 24px;
    padding-top: 14px;
    position: relative;

    .search-close {
      position: absolute;
      right: 20px;
      top: 45%;
    }

    ul {
      position: absolute;
      left: 5px;
      right: 5px;
      z-index: 2;
      background-color: var(--white-color);
      // /width: 100%;
      //border: 1px solid var(--black-color);
      border-top: 0;
      padding: 0;

      li {
        padding: 10px 20px;

        &:hover {
          opacity: 0.5;
          cursor: pointer;
        }
      }
    }
  }
}

.css-b62m3t-container {
  width: 100%;
}

.alert-body-icon img {
  border-radius: 8px;

  &:hover {
    cursor: pointer;
    // color: #1C4645;
    // background-color: #1C4645;
    opacity: 0.8;
    // fill: #1C4645;
    
  }
}

.search-input {
  width: 100%;
  min-width: 260px;
  padding: 9px 13px;
  border-radius: 10px;
  border: 1px solid #9c9c9c;
  font-size: 16px;

  &::placeholder {
    color: #9c9c9c;
  }
}

.checkbox-item {
  margin: 10px 0;

  input+label {
    margin-left: 5px;
  }
}

.filter-group-dropdown-list {
  position: relative;
}

.filter-type-dropdown {
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--white-color);
  z-index: 5;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  button {
    width: 100%;
    background-color: var(--white-color);
    border: 0;
    padding: 15px;

    &:hover {
      // opacity: 0.5;
      cursor: pointer;
      color: #1C4645;
      background-color: #e9ecef;
      // color: var(--white-color);
      // background-color: #30A953;
    }
  }
}

.table .alert-head {
  padding: 15px 0;
  border: 1px solid #b5b5b5;
  text-align: center;

  li {
    font-size: 20px;
  }
}

.alert-name {
  // width: 500px;
  width: 25%;
  // padding-left: 55px;
}

.alert-level {
  width: 25%;
}

.alert-edt {
  width: 25%;
}

.alert-id {
  // width: 200px;
  width: 25%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.overlay {
  padding: 50px;
}

.loading {
  height: 100vh;
}

.loading-Overlay {
  border: 5px solid #f3f3f3;
  /* Light grey */
  border-top: 5px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  text-align: center;
  margin: 20px auto;
}

.alert-body {
  margin: 20px auto;

  &-list {
    padding: 0;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.25);
  }

  .index {
    width: 58px;
    padding: 20px 0;
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
    box-shadow: 1px 0px 1px 0px rgba(0, 0, 0, 0.25);
  }

  &-name {
    // width: 440px;
    width: 100%;

    select {
      margin: 0 5px;
    }

    p {
      display: inline-block;
      margin-bottom: 0;
      cursor: pointer;
    }
  }

  &-id {
    // width: 24px;
    width: 100%;
    @extend .flex;
    @extend .justify-center;
    @extend .align-center;
  }

  &-add {
    // width: 380px;
    width: 100%;
    text-align: center;
    margin-right: auto;
  }

  &-icon {
    padding: 0 23px;
    cursor: pointer;
  }

  img {
    width: 30px;
    padding: 4px;
    border-radius: 4px;

    &:hover {
      color: #30a953;
      background-color: #30a953;
    }
  }
}

.toggle {
  border-top: 1px solid grey;

  td+td {
    padding-left: 50px;
  }

  table {
    padding: 20px 80px 20px 80px;

    +div {
      border-top: 1px solid grey;
      padding: 20px 80px;

      strong {
        padding-right: 10px;
      }
    }
  }
}

.dropdownConfigure {
  display: inline-block;
  position: relative;
  margin-left: 20px;
  margin-right: 20px;

  img {
    margin-left: 7px;
  }
}

.colorRed.disabled {
  color: rgb(156, 163, 175);

  * {
    cursor: not-allowed;
  }
}

.Green {
  font-size: 0;
  margin: 5px 0;
  @extend .flex;
  @extend .align-center;
  cursor: pointer;

  &::before {
    content: "";
    background-color: #30a953;
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
  }
}

.Red {
  font-size: 0;
  margin: 5px 0;
  cursor: pointer;
  @extend .flex;
  @extend .align-center;

  &::before {
    content: "";
    background-color: red;
    width: 12px;
    height: 12px;
    display: inline-block;
    border-radius: 100%;
  }
}

.dropdown-content-category {
  position: absolute;
  background-color: var(--white-color);
  border: 1px solid black;

  &::before {
    display: none;
  }

  &.green .Green {
    display: none;
  }

  &.Red .Red {
    display: none;
  }
}

.frequently {
  .custom-dates {
    .frequently-input {
      width: 550px;
    }
  }

  .date-picker {
    padding: 0 20px;
    text-align: center;

    >input {
      width: 310px;
      padding: 14px;
    }
  }

  .dropdown {
    margin: 0;
    width: 200px;
  }

  &-input {
    width: 850px;

    input {
      width: 100%;
      padding: 14px;
      border-radius: 5px;
    }
  }
}

.frequently-input {
  padding: 0;
}

.frequently-btn {
  width: auto;
  padding: 5px;
  margin-top: 20px;
}

.rdrDefinedRangesWrapper {
  display: none;
}

.cursor-pointer {
  cursor: pointer !important;
}


.pointer-cell:hover {
  cursor: pointer;
  // margin-right: 5px;
  opacity: 0.85;
}

.pointer-cell-icon:hover {
  cursor: pointer;
}

.action-icon {
  border-style: none;
  // position: absolute;
  background: none;
}

.action-popup {
  display: flex;
  // flex-direction: column;
  width:  180px;
  padding: 5px;
  justify-content: space-between;
  width: fit-content;
}

.search-box {
  max-width: fit-content;
  // margin: 14px 24px;
  // padding-top: 14px;
  position: relative;

  .search-close {
    position: absolute;
    // right: 20px;
    // top: 45%;
  }
}


//  // // // /
.freq {
  &-input {

    // width: 35%;
    input {
      width: 100%;
      padding: 14px;
      border-radius: 15px;
    }
  }
}

.freq-input {
  padding: 10px 10px 20px 10px;
}

.freq-btn {
  width: auto;
  margin-top: 20px;
}

.sumry {
  margin-top: 20px;
  padding: 25px 0px;
}

.summary3 {
  margin-top: 20px;
  padding: 20px 10px;
}

.date-picker {
  position: absolute;
  right: 0;
  z-index: 50;
}


.container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%; /* Full width of the screen */
  height: 30vh; /* Full height of the screen */
  background-color: lightgray; /* Just for visualization */
}

.centered-text {
  text-align: center;
  font-size: 18px;
}

.mail-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.shape-selector {
  display: flex;
  align-items: center;
}

.shape-label {
  display: flex;
  align-items: center;
  margin: 10px;
  cursor: pointer;
}

.shape-label input[type="radio"] {
  display: none; /* Hide the default radio button */
}

.shape-label svg {
  margin-right: 10px;
}
