@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

:root {
    --primary: #74C38A;
    --secondary: #2562FF;
    --white-color: #FFFFFF;
    --black-color: #000;
}

$color-white: var(--white-color);

body {
    font-size: 16px;
    line-height: normal;
    color: var(--black-color);
    margin: 0;
    font-family: 'Roboto', sans-serif;
    box-sizing: border-box;
}

* {
    box-sizing: border-box;
}

.container-small {
    max-width: 1160px;
    margin: auto;
}

input {
    font-size: 18px;
    color: var(--black-color);
    border: 1px solid #9C9C9C;
}

input::placeholder {
    font-size: 18px;
    color: #9C9C9C;
}

input:focus-within {
    outline: none;
}

.input-1 {
    font-size: 18px;
    color: var(--black-color);
    border-width: 0 0 2px 0;
    border-bottom: 2px solid var(--black-color);
    width: 100%;
    padding: 14px;

    +.password {
        margin-top: 45px;
    }
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
    border-radius: 5px;
}

.pointer {
    cursor: pointer;
}

.none {
    pointer-events: none;
}

.button {
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    color: var(--white-color);
    background-color: var(--primary);
    border: 2px solid var(--primary);
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    font-family: 'Roboto', sans-serif;

    &:hover {
        color: var(--primary);
        background-color: transparent;
        cursor: pointer;
    }
}

.button {
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    color: var(--white-color);
    background-color: #1C4546;
    border: 2px solid #1C4546;
    padding: 10px 0;
    width: 100%;
    font-family: 'Roboto', sans-serif;

    &:hover {
        color: #1C4645;
        background-color: transparent;
        cursor: pointer;
    }
}

h1 {
    font-size: 45px;
    line-height: normal;
    font-weight: 500;
    margin: 0 0 50px;
    text-align: center;
}

h2 {
    font-size: 24px;
    font-weight: 400;
    margin: 0 0 20px;
}

h3 {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 20px;
}

.thirdh3 {
    margin-bottom: 0;
}

h4 {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 26px;
}

a {
    color: var(--secondary);
    text-decoration: none;

    &:hover {
        color: var(--primary);
        text-decoration: underline;
    }
}

p {
    margin: 0 0 12px;
}

strong {
    font-weight: 500;
}

.list-none {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.m-0 {
    margin: 0;
}

.mt-12 {
    margin-top: 12px;
}

.mt-35 {
    margin-top: 35px;
}

.mb-35 {
    margin-bottom: 35px;
}

.mt-45 {
    margin-top: 45px;
}

.mt-100 {
    margin-top: 62px;
}

.bg-color {
    background-color: #EFEFEF;
}

.bg-white {
    background-color: $color-white;
    border-radius: 10px;
}

.shadow {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.25);
}

.flex {
    display: flex;
}

.flex-direction {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-space {
    justify-content: space-between;
}

.align-top {
    align-items: flex-start;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.form-field {
    padding: 35px 50px;
    border-bottom: 1px solid #B5B5B5;

    label {
        width: 100px;
        display: inline-block;
        margin-right: 100px;
    }

    input {
        width: 50%;
        // padding: 0 10px;
        border-bottom-width: 1px;
    }
}

// // // // //
.apply {
    position: absolute;
    right: 1%;
    bottom: 9px;
    z-index: 50;
}

.apply .button {
    border-radius: 10px;
    width: 140px;
}

.form-field1 {
    padding: 20px 25px;
    // border-bottom: 1px solid #B5B5B5;
    width: 50%;

    label {
        width: 100px;
        display: inline-block;
        // margin-right: 100px;
    }

    input {
        width: 300px;
        /* border-bottom-width: 1px; */
        border: 1px solid #9C9C9C;
        border-radius: 15px;
    }
}

.newusers {
    margin-top: 30px;
}

.innerform {
    border-bottom: 1px solid #B5B5B5;
    display: flex;
    align-items: center;
    gap: 40px;
    padding: 10px 0;
}

.gap {
    gap: 40px;
}

.btn {
    text-align: end;
}

.btn .button {
    margin: 0 10px 10px;
}

.tbl {
    align-items: center;
    justify-content: center;
    padding-bottom: 15px;
}

.tblname {
    // align-items: center;
    display: flex;
    justify-content: start;
    padding-bottom: 15px;
}

.email-submit {
    font-size: 18px;
    line-height: normal;
    font-weight: 500;
    color: var(--white-color);
    background-color: #1C4546;
    border: 2px solid var(--primary);
    // padding: 15px;
    width: 100%;
    font-family: 'Roboto', sans-serif;
    border-radius: 10px;
    width: 140px;
    height: 50px;

    &:hover {
        color: var(--primary);
        background-color: transparent;
        cursor: pointer;
    }
}


@media only screen and (max-width: 1280px){
    .button {
        font-size: 13px;
        padding: 7px 0;
    }
    h1 {
        font-size: 30px;
        line-height: normal;
        font-weight: 500;
        margin: 0 0 50px;
        text-align: center;
    }
}